<template>
    <div class="my">
      <router-link tag="div" class="top" to="/personInfo">
          <img v-if="!info.avatar" class="touxiang" src="../../image/first/head.jpg" />
          <img v-if="info.avatar" class="touxiang" :src="info.avatar" />
        <span class="right">
          <span class="t-name">{{info.username}}</span>
          <span class="t-num" v-if="info.identity =='老师'">老师 | 编号{{info.studyNumber}}</span>
          <span class="t-num" v-if="info.identity !='老师'">{{ info.grade }} | 学号{{info.studyNumber}}</span>
        </span>
        <i class="arrow" />
      </router-link>

      <div class="two">
        <router-link class="two-li" to="/pingTuan" tag="div">
          <img src="../../image/first/home-8.png" />
          <p>我的拼团</p>
        </router-link>
        <router-link class="two-li" to="/scoreShop" tag="div">
          <img src="../../image/first/home-9.png" />
          <p>积分商城</p>
        </router-link>
      </div>

      <router-link class="my-list" to="/message" tag="div">
        <span class="li-left">
          <img src="../../image/second/tongzhi.png" />
          <span class="word">消息中心</span>
        </span>
        <i class="list-arrow" />
      </router-link>

      <div class="my-list" @click="copyLink">
        <span class="li-left">
          <img src="../../image/second/fenxiang.png" />
          <span class="word">分享明九章</span>
        </span>
        <i class="list-arrow" />
      </div>

      <div class="my-list" @click="openApp" v-if="info.identity =='老师'">
        <span class="li-left">
          <img src="../../image/second/work.png" />
          <span class="word">作业批改</span>
        </span>
        <i class="list-arrow" />
      </div>

      <a class="my-list" href="tel:15057060226">
        <span class="li-left">
          <img src="../../image/second/kefu.png" />
          <span class="word">客服电话</span>
        </span>
        <i class="list-arrow" />
      </a>

      <router-link class="my-list space" to="/setting" tag="div">
        <span class="li-left">
          <img src="../../image/second/setting.png" />
          <span class="word">设置</span>
        </span>
        <i class="list-arrow" />
      </router-link>
      <Tips :tip="msg" /> 
    </div>
</template>

<script>
import { formatDate, baseUrl, copyToDoc } from '../../common/util';
export default {
  name: 'Home',
  components: {
    
  },
  filters: {
    capitalize: function (value) {
      if (!value) return 0
      return value.toFixed(2)
    }
  },
  data () {
      return {
        info: {},
        msg: ''
      }
  },
  beforeMount() {
      document.title = '我的';
  },
  mounted() {
    let info = localStorage.getItem('ng-data');
    if (info) {
      this.info = JSON.parse(info);
    }
  },
  methods: {
    openApp() {
      alert('请打开明九章app，进行批改')
    },
    copyLink() {
      const href = `${window.location.origin}`;
      copyToDoc(href, () => {
        this.msg = '复制成功，可以直接分享链接！';
      })
    }
  }

}
</script>

<style lang="less" scoped>
    .my {
      min-height: 100vh;
      background-color: #f4f6f6;
      padding-bottom: 2rem;
      box-sizing: border-box;
      .top {
        height: 2.5rem;
        background-color: #79cdca;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        .touxiang {
          width: 1rem;
          height: 1rem;
          margin-left: 0.5rem;
          border-radius: 1rem;
        }
        .right {
          color: white;
          margin-left: 0.2rem;
          .t-name {
            display: block;
            font-size: 0.36rem;
          }
          .t-num {
            display: block;
            font-size: 0.3rem;
            box-sizing: border-box;
          }
        }
        .arrow {
          position: absolute;
          right: 0.3rem;
          top: 50%;
          transform: translateY(-50%);
          width: 0.39rem;
          height: 0.39rem;
          background-image: url('../../image/first/right.png');
          background-size: 100% 100%;
          display: block;
        }
      }
      .two {
        height: 2rem;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #d9d9d9;
        background-color: white;
        .two-li {
          width: 50%;
          text-align: center;
          cursor: pointer;
          img {
            display: block;
            margin: 0 auto;
            width: 1rem;
            height: 1rem;
            border-radius: 1rem;
          }
          p {
            font-size: 0.28rem;
            margin-top: 0.1rem;
          }
        }
      }
      .my-list {
        &.space {
          margin-top: 0.2rem;
        }
        text-decoration: none;
        height: 1.09rem;
        padding: 0 0.3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #d9d9d9;
        background-color: white;
        position: relative;
        color: #333333;
        cursor: pointer;
        .li-left {
          display: flex;
          align-items: center;
          img {
            width: 0.45rem;
            height: 0.45rem;
            display: inline-block;
            margin-right: 0.3rem;
          }
          .word {
            font-size: 0.32rem;
          }
        }
        .list-arrow {
            // position: absolute;
            // right: 0.3rem;
            // top: 50%;
            // transform: translateY(-50%);
            width: 0.39rem;
            height: 0.39rem;
            background-image: url('../../image/second/arrow1.png');
            background-size: 100% 100%;
            display: block;
          }
      }
    }
</style>
